var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('Loading'):_c('div',{staticClass:"brands-list"},[_c('b-card',[_c('div',{staticClass:"p-2 custom-search d-flex align-items-center justify-content-between mb-1"},[_c('h1',{staticClass:"m-0 page-title"},[_vm._v(_vm._s(_vm.$t("users/brand-report")))]),_c('div',{staticClass:"d-flex align-items-center"},[(_vm.globalPermission.brands && _vm.globalPermission.brands.store)?_c('add-brand-component',{on:{"updateData":_vm.getBrandList}}):_vm._e()],1)]),_c('vue-good-table',{attrs:{"columns":_vm.tableHeader,"rows":_vm.tableBody,"styleClass":"table-custom-style vgt-table striped condensed","pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      },"search-options":{
        enabled: true,
        skipDiacritics: true,
        placeholder: _vm.$t('search_placeholder'),
      }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label === 'Symbol')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("symbol"))+" ")]):(props.column.label === 'Name')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("brand/name"))+" ")]):(props.column.label === 'Priority')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("priority"))+" ")]):(props.column.label === 'Visibility')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("visibility"))+" ")]):(
            props.column.label === 'Actions' &&
            _vm.globalPermission.brands &&
            (_vm.globalPermission.brands.update ||
              _vm.globalPermission.brands.destroy)
          )?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.action"))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'symbol')?_c('span',{staticClass:"text-nowrap"},[(props.row.logo)?_c('b-avatar',{attrs:{"variant":"info","src":props.row.logo.path}}):_c('b-avatar',{attrs:{"variant":"info","src":_vm.$helpers.uiAvatar(props.row.name)}})],1):(props.column.field === 'visibility')?_c('span',{staticClass:"text-nowrap custom-toggle"},[_c('b-form-checkbox',{attrs:{"switch":"","disabled":_vm.globalPermission.brands && !_vm.globalPermission.brands.update},on:{"change":function (e) { return _vm.handleBrandVisibility(e, props.row.slug); }},model:{value:(props.row.is_visible),callback:function ($$v) {_vm.$set(props.row, "is_visible", $$v)},expression:"props.row.is_visible"}})],1):(props.column.field === '#')?_c('span',{staticClass:"text-nowrap custom-toggle"},[_vm._v(" "+_vm._s(props.row.index)+" ")]):(props.column.field === 'priority')?_c('span',{staticClass:"text-nowrap"},[(props.row.priority === 5)?_c('b-badge',{attrs:{"variant":"light-success"}},[_vm._v(" "+_vm._s(_vm.getPriorityName(props.row.priority))+" ")]):(props.row.priority === 4)?_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.getPriorityName(props.row.priority))+" ")]):(props.row.priority === 3)?_c('b-badge',{attrs:{"variant":"light-secondary"}},[_vm._v(" "+_vm._s(_vm.getPriorityName(props.row.priority))+" ")]):(props.row.priority === 2)?_c('b-badge',{attrs:{"variant":"light-warning"}},[_vm._v(" "+_vm._s(_vm.getPriorityName(props.row.priority))+" ")]):(props.row.priority === 1)?_c('b-badge',{attrs:{"variant":"light-danger"}},[_vm._v(" "+_vm._s(_vm.getPriorityName(props.row.priority))+" ")]):_vm._e()],1):(
            props.column.field === 'actions' &&
            _vm.globalPermission.brands &&
            (_vm.globalPermission.brands.update || _vm.globalPermission.destroy)
          )?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":"","text":"Block Level Dropdown Menu","block":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"size":"16","icon":"MoreVerticalIcon"}})]},proxy:true}],null,true)},[(
                  _vm.globalPermission.brands && _vm.globalPermission.brands.update
                )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.changeBrandId(props.row.slug)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.edit")))])],1):_vm._e(),(
                  _vm.globalPermission.brands && _vm.globalPermission.brands.destroy
                )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.sweetAlertConfirm(props.row.slug)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Trash2Icon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.delete")))])],1):_vm._e()],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-center flex-wrap"},[_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('template',{slot:"emptystate"},[_c('div',{staticClass:"empty-table-message"},[_c('p',[_vm._v(_vm._s(_vm.$t("noData")))])])])],2)],1),_c('edit-brand-component',{attrs:{"brandSlug":_vm.brandSlug},on:{"updateData":_vm.getBrandList}}),_c('overlay-component',{attrs:{"isLoading":_vm.overlayLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }