<template>
  <div class="add-users-component">
    <b-button @click="$bvModal.show('add-modal-brand')" variant="primary">
      <feather-icon icon="PlusIcon" class="text-white" size="16" />
      {{ $t("brands/brands_list/add_brand") }}
    </b-button>

    <b-modal
      header-class="modal-header"
      modal-class="modal add-brand"
      title-class="modal-title"
      :title="$t('add_brand')"
      @cancel="resetData"
      @close="resetData"
      hide-footer
      id="add-modal-brand"
      :ok-title-html="$t('g.ok')"
      :cancel-title="$t('cancel-button')"
    >
      <validation-observer ref="addBrandForm">
        <b-form @submit.prevent="validate">
          <b-row>
            <b-col cols="12 mb-2">
              <!-- start image input -->
              <validation-provider
                #default="{ errors }"
                name="Image"
                rules="required"
              >
                <div class="brand-profile-img">
                  <img
                    alt=""
                    class="brand-img-placeholder"
                    :src="imagePlaceholder"
                  />
                  <img
                    :src="imageIcon"
                    alt="profile image"
                    class="brand-profile-img-icon"
                  />
                  <b-form-file
                    type="file"
                    id="Image"
                    v-model="formData.logo"
                    class="brand-img-input"
                    @change="handleFileChange"
                  />
                </div>
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
                <RecommendationUploadImage
                  :dimensions="{ width: 1200, height: 750 }"
                />
              </validation-provider>
              <!-- end image input -->
            </b-col>

            <b-col cols="12">
              <!-- Start Input -->
              <b-form-group :label="$t('brand_name')" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="formData.name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Emd Input -->
            </b-col>

            <b-col cols="12">
              <!-- Start Dropdown -->
              <b-form-group :label="$t('priority')" label-for="rulesss">
                <validation-provider
                  #default="{ errors }"
                  name="priority"
                  rules="required"
                >
                  <b-form-select
                    v-model="formData.priority"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-select-option :value="null" :disabled="true">
                      {{ $t("brands/add_brands/select_brand_priority") }}
                    </b-form-select-option>
                    <b-form-select-option
                      v-for="priority in brandPriority"
                      :key="priority.id"
                      :value="{ name: priority.name, id: priority.id }"
                    >
                      {{ priority.name }}
                    </b-form-select-option>
                  </b-form-select>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Dropdown -->
            </b-col>

            <b-col cols="12" class="mt-2">
              <!-- Start Dropdown -->
              <b-form-group label-for="visibility">
                <validation-provider
                  style="display: flex"
                  #default="{ errors }"
                  name="visibility"
                >
                  <b-form-checkbox
                    switch
                    variant="success"
                    v-model="formData.is_visible"
                    :state="errors.length > 0 ? false : null"
                  >
                  </b-form-checkbox>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                  <p>{{ $t("visibility") }}</p>
                </validation-provider>
              </b-form-group>
              <!-- End Dropdown -->
            </b-col>
          </b-row>
          <b-row align-h="end" class="my-1">
            <b-button variant="outline" @click="resetData">{{
              $t("cancel")
            }}</b-button>
            <b-button variant="primary" class="mx-1" type="submit">{{
              $t("add_brand")
            }}</b-button>
          </b-row>
        </b-form>
      </validation-observer>
      <OverlayComponent :isLoading="overlayLoading" />
    </b-modal>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BAvatar,
  BFormFile,
  BModal,
  BForm,
} from "bootstrap-vue";
import { required } from "@validations";
import OverlayComponent from "@/components/shared/OverlayComponent";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";

export default {
  name: "AddBrandComponent",
  components: {
    BFormCheckbox,
    OverlayComponent,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BFormFile,
    BForm,
    BModal,
    RecommendationUploadImage
  },
  data() {
    return {
      required,
      overlayLoading: false,
      formData: {
        name: null,
        logo: null,
        is_visible: true,
        priority: null,
      },
      brandPriority: [
        {
          name: this.$t("brands/brand_list/very_low"),
          id: 1,
        },
        {
          name: this.$t("brands/brand_list/low"),
          id: 2,
        },
        {
          name: this.$t("brands/brand_list/average"),
          id: 3,
        },
        {
          name: this.$t("brands/brand_list/high"),
          id: 4,
        },
        {
          name: this.$t("brands/brand_list/very_high"),
          id: 5,
        },
      ],
      imageIcon: require("../../../assets/images/icons/prime_camera.svg"),
    };
  },
  computed: {
    imagePlaceholder() {
      return this.formData.logo && URL.createObjectURL(this.formData.logo);
    },
  },
  methods: {
    clearForm() {
      for (let item in this.formData) {
        item === "is_visible"
          ? (this.formData[item] = true)
          : (this.formData[item] = null);
      }
    },
    async submit() {
      try {
        this.overlayLoading = true;
        const reqFormData = new FormData();
        this.formData.name && reqFormData.append("name", this.formData.name);
        this.formData.logo && reqFormData.append("logo", this.formData.logo);
        reqFormData.append("is_visible", this.formData.is_visible ? 1 : 0);
        this.formData.priority.id &&
          reqFormData.append("priority", this.formData.priority.id);
        const res = await this.$http.post("admin/brands", reqFormData);
        if (res.status === 200 || res.status === 201) {
          this.clearForm();
          this.$helpers.makeToast(
            "success",
            res.data.message,
            res.data.message
          );
          this.$emit("updateData");
          this.$bvModal.hide("add-modal-brand");
        }
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.overlayLoading = false;
      }
    },
    validate() {
      this.$refs.addBrandForm
        .validate()
        .then((success) => success && this.submit());
    },
    handleFileChange(event) {
      this.formData.logo = event.target.files[0];
    },
    resetData() {
      for (const item in this.formData) {
        this.formData[item] = null;
      }
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />
