var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-users-component"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$bvModal.show('add-modal-brand')}}},[_c('feather-icon',{staticClass:"text-white",attrs:{"icon":"PlusIcon","size":"16"}}),_vm._v(" "+_vm._s(_vm.$t("brands/brands_list/add_brand"))+" ")],1),_c('b-modal',{attrs:{"header-class":"modal-header","modal-class":"modal add-brand","title-class":"modal-title","title":_vm.$t('add_brand'),"hide-footer":"","id":"add-modal-brand","ok-title-html":_vm.$t('g.ok'),"cancel-title":_vm.$t('cancel-button')},on:{"cancel":_vm.resetData,"close":_vm.resetData}},[_c('validation-observer',{ref:"addBrandForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12 mb-2"}},[_c('validation-provider',{attrs:{"name":"Image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"brand-profile-img"},[_c('img',{staticClass:"brand-img-placeholder",attrs:{"alt":"","src":_vm.imagePlaceholder}}),_c('img',{staticClass:"brand-profile-img-icon",attrs:{"src":_vm.imageIcon,"alt":"profile image"}}),_c('b-form-file',{staticClass:"brand-img-input",attrs:{"type":"file","id":"Image"},on:{"change":_vm.handleFileChange},model:{value:(_vm.formData.logo),callback:function ($$v) {_vm.$set(_vm.formData, "logo", $$v)},expression:"formData.logo"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))]),_c('RecommendationUploadImage',{attrs:{"dimensions":{ width: 1200, height: 750 }}})]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('brand_name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('priority'),"label-for":"rulesss"}},[_c('validation-provider',{attrs:{"name":"priority","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.priority),callback:function ($$v) {_vm.$set(_vm.formData, "priority", $$v)},expression:"formData.priority"}},[_c('b-form-select-option',{attrs:{"value":null,"disabled":true}},[_vm._v(" "+_vm._s(_vm.$t("brands/add_brands/select_brand_priority"))+" ")]),_vm._l((_vm.brandPriority),function(priority){return _c('b-form-select-option',{key:priority.id,attrs:{"value":{ name: priority.name, id: priority.id }}},[_vm._v(" "+_vm._s(priority.name)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"visibility"}},[_c('validation-provider',{staticStyle:{"display":"flex"},attrs:{"name":"visibility"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":"","variant":"success","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.is_visible),callback:function ($$v) {_vm.$set(_vm.formData, "is_visible", $$v)},expression:"formData.is_visible"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))]),_c('p',[_vm._v(_vm._s(_vm.$t("visibility")))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"my-1",attrs:{"align-h":"end"}},[_c('b-button',{attrs:{"variant":"outline"},on:{"click":_vm.resetData}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('b-button',{staticClass:"mx-1",attrs:{"variant":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.$t("add_brand")))])],1)],1)],1),_c('OverlayComponent',{attrs:{"isLoading":_vm.overlayLoading}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }