<template>
  <Loading v-if="isLoading" />
  <div v-else class="brands-list">
    <b-card>
      <div
        class="p-2 custom-search d-flex align-items-center justify-content-between mb-1"
      >
        <h1 class="m-0 page-title">{{ $t("users/brand-report") }}</h1>
        <div class="d-flex align-items-center">
          <add-brand-component
            v-if="globalPermission.brands && globalPermission.brands.store"
            @updateData="getBrandList"
          />
        </div>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: $t('search_placeholder'),
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'Symbol'" class="text-nowrap">
            {{ $t("symbol") }}
          </span>
          <span v-else-if="props.column.label === 'Name'" class="text-nowrap">
            {{ $t("brand/name") }}
          </span>
          <span
            v-else-if="props.column.label === 'Priority'"
            class="text-nowrap"
          >
            {{ $t("priority") }}
          </span>
          <span
            v-else-if="props.column.label === 'Visibility'"
            class="text-nowrap"
          >
            {{ $t("visibility") }}
          </span>
          <span
            v-else-if="
              props.column.label === 'Actions' &&
              globalPermission.brands &&
              (globalPermission.brands.update ||
                globalPermission.brands.destroy)
            "
            class="text-nowrap"
          >
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'symbol'" class="text-nowrap">
            <b-avatar
              v-if="props.row.logo"
              variant="info"
              :src="props.row.logo.path"
            />
            <b-avatar
              v-else
              variant="info"
              :src="$helpers.uiAvatar(props.row.name)"
            />
          </span>
          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === 'visibility'"
          >
            <b-form-checkbox
              switch
              :disabled="
                globalPermission.brands && !globalPermission.brands.update
              "
              v-model="props.row.is_visible"
              @change="(e) => handleBrandVisibility(e, props.row.slug)"
            />
          </span>
          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === '#'"
          >
            {{ props.row.index }}
          </span>
          <span
            class="text-nowrap"
            v-else-if="props.column.field === 'priority'"
          >
            <b-badge variant="light-success" v-if="props.row.priority === 5">
              {{ getPriorityName(props.row.priority) }}
            </b-badge>
            <b-badge
              variant="light-primary"
              v-else-if="props.row.priority === 4"
            >
              {{ getPriorityName(props.row.priority) }}
            </b-badge>
            <b-badge
              variant="light-secondary"
              v-else-if="props.row.priority === 3"
            >
              {{ getPriorityName(props.row.priority) }}
            </b-badge>
            <b-badge
              variant="light-warning"
              v-else-if="props.row.priority === 2"
            >
              {{ getPriorityName(props.row.priority) }}
            </b-badge>
            <b-badge
              variant="light-danger"
              v-else-if="props.row.priority === 1"
            >
              {{ getPriorityName(props.row.priority) }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="
              props.column.field === 'actions' &&
              globalPermission.brands &&
              (globalPermission.brands.update || globalPermission.destroy)
            "
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    size="16"
                    icon="MoreVerticalIcon"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="
                    globalPermission.brands && globalPermission.brands.update
                  "
                  @click="changeBrandId(props.row.slug)"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    globalPermission.brands && globalPermission.brands.destroy
                  "
                  @click="sweetAlertConfirm(props.row.slug)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-center flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <edit-brand-component :brandSlug="brandSlug" @updateData="getBrandList" />
    <overlay-component :isLoading="overlayLoading" />
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BDropdownItem,
  BDropdown,
  BButton,
  BFormCheckbox,
  BAvatar,
  BBadge,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import dateTimeInFormat from "@/composables/format-date.js";
import Loading from "@/components/shared/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import AddBrandComponent from "@/components/Brands/AddBrandComponent";
import EditBrandComponent from "@/components/Brands/EditBrandComponent";
import Swal from "sweetalert2";

export default {
  name: "BrandsList",
  components: {
    AddBrandComponent,
    EditBrandComponent,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    Loading,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    OverlayComponent,
    BBadge,
  },
  data() {
    return {
      page_data: null,
      isLoading: true,
      overlayLoading: false,
      pageLength: 20,
      searchTerm: "",
      tableBody: [],
      brandSlug: null,
      formateDate: dateTimeInFormat,
      plusIcon: require("@/assets/images/icons/ic_round-add.svg"),
      tableHeader: [
        {
          label: "#",
          field: "#",
          sortable: false,
        },
        {
          label: "Symbol",
          field: "symbol",
          sortable: false,
        },
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Priority",
          field: "priority",
          sortable: false,
        },
        {
          label: "Visibility",
          field: "visibility",
          sortable: false,
        },
        {
          label: "Actions",
          field: "actions",
          sortable: false,
        },
      ],
    };
  },
  beforeMount() {
    this.getBrandList();
  },
  created() {
    if (
      this.globalPermission.brands &&
      !(
        this.globalPermission.brands.update ||
        this.globalPermission.brands.destroy
      )
    ) {
      this.tableHeader = this.tableHeader.filter(
        (ele) => "Actions" !== ele.label
      );
    }
  },
  methods: {
    async getBrandList() {
      try {
        const response = (await this.$http.get("admin/brands")).data.data;
        this.tableBody = [response].flat().map((item, index) => ({
          index: index + 1,
          id: item.id,
          name: item.name,
          priority: item.priority,
          is_visible: item.is_visible,
          logo: item.logo,
          slug: item.slug,
        }));
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    sweetAlertConfirm(brandId) {
      Swal.fire({
        title: this.$t("are_you_sure"),
        text: this.$t("you_wont_be_able_to_revert_this_action"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("g.yes_delete_it"),
        cancelButtonText: this.$t("g.cancel-button"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$http
            .delete(`admin/brands/${brandId}`)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.$helpers.makeToast(
                  "success",
                  res.data.message,
                  res.data.message
                );
                this.getBrandList();
              }
            })
            .catch((err) => {
              this.$helpers.handleError(err);
            });
        }
      });
    },
    // async deleteBrand(brandId) {
    //   try {
    //     this.$helpers.sweetAlertConfirm(
    //       this.$t("are_you_sure"),
    //       this.$t("you_wont_be_able_to_revert_this_action"),
    //       "warning",
    //       this.$t("g.yes_delete_it"),
    //       this.$t("g.cancel-button"),
    //       () => this.handleDeleteBrand(brandId),
    //       "The_brand_has_been_successfully_deleted"
    //     );
    //     this.overlayLoading = true;
    //   } catch (error) {
    //     this.isError = true;
    //   } finally {
    //     this.overlayLoading = false;
    //   }
    // },
    // handleDeleteBrand(brandId) {
    //   this.$http
    //     .delete(`admin/brands/${brandId}`)
    //     .then((res) => {
    //       if (res.status === 200) {
    //         this.getBrandList();
    //       }
    //     })
    //     .catch((err) => {
    //
    //     });
    // },
    async handleBrandVisibility(checked, slug) {
      try {
        this.overlayLoading = true;
        const data = {
          is_visible: checked,
        };
        const res = await this.$http.post(
          `admin/brands/${slug}?_method=put`,
          data
        );
        if (res.status > 299) {
          throw Error();
        }
        this.$helpers.makeToast("success", res.data.message, res.data.message);
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.overlayLoading = false;
      }
    },
    changeBrandId(brandSlug) {
      this.$bvModal.show("edit-modal-brand");
      this.brandSlug = brandSlug;
    },
    AddNewBrand() {
      this.$bvModal.show("add-modal-brand");
    },
    filterFn: function (data, filterString) {
      var x = parseInt(filterString);
      return data >= x - 5 && data <= x + 5;
    },
    getPriorityName(brandPriority) {
      let priorityName = "";
      switch (brandPriority) {
        case 1:
          priorityName = this.$t("brands/brand_list/very_low");
          break;
        case 2:
          priorityName = this.$t("brands/brand_list/low");
          break;
        case 3:
          priorityName = this.$t("brands/brand_list/average");
          break;
        case 4:
          priorityName = this.$t("brands/brand_list/high");
          break;
        case 5:
          priorityName = this.$t("brands/brand_list/very_high");
          break;

        default:
          priorityName = this.$t("brands/brandsList/there-is-no-priority");
          break;
      }

      return priorityName;
    },
  },
};
</script>

<style lang="scss">
@import "./_index";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
